import React from 'react'
import PropTypes from 'prop-types'

import { Content as ContentStyled } from './styles'

const Content = ({ children }: { children: any }) => {
  return <ContentStyled>{children}</ContentStyled>
}

Content.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Content
