import styled from 'styled-components'
import media from 'styled-media-query'

import { MaxSize, Space } from 'utils/theme'

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${MaxSize.lg};
  padding-left: ${Space.default};
  padding-right: ${Space.default};
`

export const AvatarImage = styled.img`
  border-radius: 1%;
  max-width: 290px;
  margin-right: 5px;
  margin-top: 8px;
  float: right;

  ${media.lessThan('medium')`
    max-width: 250px;
  `}

  ${media.lessThan('small')`
    float: none;
    border-radius: 50%;
    max-width: 200px;
    margin: 0 auto;
  `}
`
