import styled from 'styled-components'
import media from 'styled-media-query'

import {
  Text1,
  Space,
  Heading1,
  Link,
  Heading2,
  Heading3,
  Border,
  Text2,
  Subtitle1,
} from 'utils/theme'

export const Content = styled.article`
  ${Text1}

  &:not(:first-child) {
    margin-top: ${Space.md};
  }

  &:not(:last-child) {
    margin-bottom: ${Space.md};
  }

  h1 {
    ${Heading1}

    &:not(:first-child) {
      margin-top: ${Space.lg};
    }

    &:not(:last-child) {
      margin-bottom: ${Space.default};
    }
  }

  h2 {
    ${Heading1}

    &:not(:first-child) {
      margin-top: ${Space.lg};
    }

    &:not(:last-child) {
      margin-bottom: ${Space.default};
    }
  }

  h3 {
    ${Heading2}

    &:not(:first-child) {
      margin-top: ${Space.md};
    }

    &:not(:last-child) {
      margin-bottom: ${Space.sm};
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: ${Space.default};
    }
  }

  a {
    ${Link}
  }

  blockquote {
    ${Heading3}
    border-left: ${Space.xxs} solid var(--highlightColor);
    font-style: italic;
    overflow-wrap: break-word;
    padding-bottom:  ${Space.sm};
    padding-left: ${Space.default};
    padding-top:  ${Space.sm};

    &:not(:first-child) {
      margin-top: ${Space.lg};
    }

    &:not(:last-child) {
      margin-bottom: ${Space.lg};
    }
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  ol, ul {
    padding-left: 2.5rem;

    &:not(:last-child) {
      margin-bottom: ${Space.default};
    }
  }

  li {
    &:not(:last-child) {
      margin-bottom: ${Space.sm};
    }
  }

  img,
  .gatsby-resp-image-wrapper {
    width: 100%;

    & + em {
      font-size: 1.4rem;
    }
  }

  .gatsby-highlight {
    pre[class*="language-"] {
      font-size: 1.8rem;
      padding-left: ${Space.default};

      ${media.lessThan('medium')`
        border-radius: 0;
        font-size: 1.6rem;
        margin-left: -${Space.sm};
        margin-right: -${Space.sm};
      `}
    }

    &:not(:last-child) {
      margin-bottom: ${Space.default};
    }
  }

  .language-text {
    background-color: var(--bgSecondary);
    color: var(--colorPrimary);
    font-size: 1.8rem;
    padding-bottom: .2em;
    padding-left: .3em;
    padding-right: .3em;
    padding-top: .3em;
    text-shadow: none;
  }

  hr {
    background-color: var(--borderColor);
    border: 0;
    height: 1px;
    margin-bottom: ${Space.default};
    margin-top: ${Space.default};
  }

  .twitter-tweet {
    ${Text2}

    p {
      ${Heading3}
      border-bottom: ${Border.box};
      font-style: normal;
      margin-bottom: ${Space.sm};
      padding-bottom: ${Space.sm};
    }

    a {
      ${Text2}

      &:before {
        content: '';
        display: block;
      }
    }
  }

  .support-title {
    ${Subtitle1}
  }
`
