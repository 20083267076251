import React from 'react'
import { Link } from 'gatsby'

import Container from '@/components/Container'
import SEO from '@/components/Seo'
import Content from '@/components/Content'
import { AvatarImage } from '@/components/Author/styles'
import styled from 'styled-components'
import ReactGA from 'react-ga'

const Links = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;

  a {
    padding: 8px 16px;
    background-color: #006773;
    border-radius: 9px;
    color: var(--primaryColor);
    font-size: 1.8;
    font-weight: 600;
    text-align: center;
  }
`

const trackClick = (title: string) => {
  ReactGA.event({
    category: 'Links',
    action: 'click',
    label: `Links: ${title}`,
  })
}

const ExternalLink = ({
  title,
  ...rest
}: { title: string } & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <a
      title={title}
      rel='noopener noreferrer'
      onClick={() => trackClick(title)}
      {...rest}
    >
      {title}
    </a>
  )
}

export default () => {
  return (
    <Container>
      <SEO title='Links' />
      <section
        style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '70rem' }}
      >
        <Content>
          <div
            itemScope
            itemType='http://schema.org/Person'
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <AvatarImage
              src='/icons/icon-512x512.png'
              style={{ borderRadius: '50%' }}
            />
            <h1>
              Olá, sou o <span itemProp='name'>Allison</span>!
            </h1>
            <h3 style={{ marginTop: 0 }}>Esses são meus links</h3>
            <Links>
            <ExternalLink
                href='https://produtos.allisonverdam.com.br/allison'
                title='Produtos recomendados'
                onClick={() => trackClick('produtos-allison')}
              />
              <ExternalLink
                href='https://www.youtube.com/channel/UCpAPPmgTjcAIkjM9g-HHMdQ'
                title='Youtube'
                onClick={() => trackClick('youtube')}
              />
              <ExternalLink
                href='http://www.instagram.com/allisonverdam/'
                title='Instagram'
                onClick={() => trackClick('instagram')}
              />
              <ExternalLink
                href='https://www.linkedin.com/in/allisonverdam'
                title='Linkedin'
                onClick={() => trackClick('linkedin')}
              />
               <ExternalLink
                href='https://www.instagram.com/diariodereformacasa32/'
                title='Instagram da casa'
                onClick={() => trackClick('instagram-casa')}
              />
              <Link to='/blog' title='Blog' onClick={() => trackClick('blog')}>
                Blog
              </Link>
              <ExternalLink 
                href='https://suricatos.tech/'
                title='Suricatos Tech'
                onClick={() => trackClick('suricatosTech')}
              />
            </Links>
          </div>
        </Content>
      </section>
    </Container>
  )
}
